import gsap from "../libs/gsap.min";

let linkToNext;

let links = document.querySelectorAll(".page-transition_link");

const toNextPage = () => {
    window.location = linkToNext;
    gsap.set(".page-transition", { userSelect: "all", pointerEvents: "all" });
};

const removeStyles = () => {
    gsap.set(".page-transition_block", { xPercent: -100 });
    gsap.set(".page-transition", { userSelect: "none", pointerEvents: "none" });
};

let showTimeLine = gsap.timeline({ paused: true });
let hideTimeLine = gsap.timeline();

showTimeLine.to(".page-transition_block", {
    xPercent: 0,
    stagger: 0.2,
    ease: Power4.easeInOut,
    duration: 0.75,
    onComplete: toNextPage,
});

hideTimeLine.to(".page-transition_block", {
    xPercent: 100,
    stagger: 0.2,
    ease: Power4.easeInOut,
    duration: 0.75,
    onComplete: removeStyles,
});

links.forEach((link) => {
    link.addEventListener("click", (e) => {
        e.preventDefault();
        linkToNext = link.href;
        showTimeLine.play();
    });
});
